import React from 'react';
import Hero from './components/Hero';
import Content from './components/Content';
// import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import './styles/index.css';
import './styles/Styles.css';
import './styles/Hero.css';
import './styles/ContactForm.css';
import './styles/Content.css';
import './styles/Footer.css';

function App() {
  return (
    <div className="App">
      <main>
        <Hero />
        <Content />
        {/* <ContactForm /> */}
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;

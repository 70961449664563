import React, { useState, useEffect } from 'react';
import '../styles/Hero.css';
import verticalLogo from '../assets/logo-LogoLogiks-vertical.svg';
import horizontalLogo from '../assets/logo-LogoLogiks-horizontal.svg';

const Hero = () => {
    const [logo, setLogo] = useState(window.innerWidth < 700 ? verticalLogo : horizontalLogo);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 700) {
                setLogo(verticalLogo);
            } else {
                setLogo(horizontalLogo);
            }
        };

        window.addEventListener('resize', handleResize);
        // Cleanup on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <section className="hero">
                <img 
                    src={logo} 
                    alt="LogoLogiks Logo" 
                    className="logo" 
                />
        </section>
    );
};

export default Hero;

import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <small>
      <p>LogoLogiks &copy; {year}</p>
    </small>
  );
};

export default Footer;

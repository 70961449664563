import React from 'react';
import '../styles/Content.css';

const Content = () => {
    return (
        <section className="content">
                <h2>COMING SOON!</h2>
                <h1>LogoLogiks.com </h1>
                {/* <div className='bio'>
                <article>
                <h3>Welcome to LogoLogiks:</h3> 
                <h4>Elevating Brands, Inspiring Engagement</h4>
                <p>Nestled in the vibrant city of Vancouver, BC, LogoLogiks is a premier logo, branding & web agency setting new standards in high-end branding and user experience design.</p>
                </article>
                <article>
                <h3>Crafting Bespoke Design Solutions:</h3>
                <h4>Your Story, Our Canvas</h4>
                <p>At LogoLogiks, we believe that every brand has a story waiting to be told. Our approach combines artistic vision with technical expertise to weave the essence of your brand's narrative into every pixel and print.</p>
                </article>
                <article>
                <h3>Experience Your Identity:</h3>
                <h4>Where Vision Meets Reality</h4>
                <p>We understand that your brand isn't just a logo or a website — it's an experience. That's why LogoLogiks is committed to ensuring that every interaction with your brand is memorable, engaging, and seamlessly accessible. From the first click to the final conversion, we will craft experiences together that resonate with your audience, empowering meaningful relationships with growth and prosperity.</p>
                </article>
                </div> */}
        </section>
    );
};

export default Content;
